<!--suppress ALL -->
<template>
  <v-card>
    <v-progress-linear
        :active="editando"
        height="6"
        indeterminate
        rounded
    ></v-progress-linear>

    <h1 class="ml-4">{{ formTitle }}</h1>

    <v-card-text>
      <form
          id="form"
          enctype="application/x-www-form-urlencoded"
      >
        <input
            v-model="form.id"
            type="hidden"
        >
        <v-row>
          <v-col cols="8">
            <v-autocomplete
                v-model="form.tipo_ato"
                :items="tipos"
                :rules="obrigatorio"
                clearable
                label="Tipo do Ato"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
                v-model="form.numero_ato"
                :rules="obrigatorio"
                label="Número do Ato"
                type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="form.ano_ato"
                :rules="obrigatorio"
                label="Ano do Ato"
                type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-autocomplete
                v-model="form.emitente"
                :items="emitentes"
                :rules="obrigatorio"
                clearable
                label="Emitente"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-textarea
                v-model="form.assunto"
                :rules="obrigatorio"
                label="Assunto"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
          </v-col>
        </v-row>
        <v-row>
          <v-col
              class="col-12 col-md-4"
          >
            <div style="font-size: 12px;">Anexar arquivo</div>
            <v-file-input
                :label="'Clique aqui para buscar o arquivo...'"
                accept="pdf/*"
                chips
                class="arquivoAnexo"
            >
            </v-file-input>
          </v-col>
        </v-row>
      </form>
    </v-card-text>
    <v-row>
      <template
          v-if="showErro"
      >
        <v-col
            cols="6"
        >
          <v-alert
              v-show="true"
              class="ml-4"
              elevation="2"
              type="error"
          >
            <ul>
              <li
                  v-for="(i, indice) in respErro"
                  :key="indice"
                  v-html="i"
              >
              </li>
            </ul>
          </v-alert>
        </v-col>
      </template>
    </v-row>

    <v-card-actions
        class="pa-6"
    >
      <v-row>
        <v-btn
            :loading="this.carregando"
            class="mr-1"
            color="blue darken-1"
            dark
            @click="save"
        >
          Salvar
        </v-btn>

        <v-btn
            v-if="form.id"
            color="mr-1 red darken-1"
            dark
            @click="removeAnexo(form.id)"
        >
          Excluir
        </v-btn>

        <v-btn
            color="gray darken-1"
            dark
            class="mr-1"
            @click="close"
        >
          Pesquisar
        </v-btn>
        <v-btn v-if="mostrar_arquivo"
               :loading="botao_arquivo"
               color="green green-1"
               dark
               @click="buscarAnexo"
        >
          <v-icon x-large>
            mdi-file-pdf-box
          </v-icon>
          Anexo Atual
        </v-btn>
      </v-row>
    </v-card-actions>
    <v-dialog
        v-model="dialogConfirm"
        max-width="500px"
    >
      <v-card>
        <v-card-title
            class="text-h6"
        >
          {{ message ? message : 'Registro salvo com sucesso!' }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeConfirm"
          >
            Ok
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>
<script>
import api from '@/api'

export default {
  data() {
    return {
      dialogConfirm: false,
      message: '',
      anexo: false,
      tipos: [],
      emitentes: [],
      form: {
        id: '',
        tipo_ato: '',
        ano_ato: '',
        numero_ato: '',
        numero_completo: '',
        emitente: '',
        assunto: '',
      },
      editando: true,
      carregando: false,
      botao_arquivo: false,
      showErro: false,
      respErro: [],
      valid: false,
      mostrar_arquivo: false,
      obrigatorio: [
        v => !!v || 'Este campo é obrigatório',
      ],
    }
  },
  computed: {
    formTitle() {
      let numero_completo = this.form.numero_completo;
      return this.$route.params.id === undefined || null ? 'Cadastro de Atos Normativos' : `Edição de Ato Normativo ${numero_completo} `
    },
  },
  created() {
    this.buscarTiposAtosNormativos();
    this.buscarEmitentes();
    let id = this.$route.params.id;
    if (id !== undefined) {
      api.get(`${process.env.VUE_APP_API}/ato-normativo/edit/${id}`)
          .then((r) => {
            this.form.id = r.data.id;
            this.form.tipo_ato = r.data.ato_normativo_tipo_id;
            this.form.numero_ato = r.data.numero_ato;
            this.form.ano_ato = r.data.ano_ato;
            this.form.emitente = r.data.emitente_id;
            this.form.assunto = r.data.assunto;
            this.form.numero_completo = r.data.numero_completo;
          })
          .finally(() => this.editando = false);
      this.mostrar_arquivo = true;
    } else {
      this.form.id = '';
      this.form.titulo = '';
      this.editando = false;
    }
  },

  methods: {
    buscarAnexo() {
      let id = this.$route.params.id;
      this.botao_arquivo = true;
      api.get(`${process.env.VUE_APP_API}/ato-normativo/buscar-anexo/${id}`, {responseType: 'blob'})
          .then((r) => {
            let fileURL = URL.createObjectURL(r.data);
            window.open(fileURL);
          })
          .finally(() => {
            this.botao_arquivo = false;
          });
    },
    serialize() {
      let form = document.getElementById('form');
      let formData = new FormData(form);

      for (let campo in this.form) {
        formData.append(campo, this.form[campo] ?? '');
      }

      let uploads = document.querySelectorAll('input[type="file"]');

      uploads.forEach(function (item) {
        let arquivo = item.files[0]
        if (typeof arquivo !== 'undefined') {
          formData.append('anexo', arquivo);
        }
      });
      return formData
    },

    removeAnexo(id) {
      if (confirm('Deseja realmente remover este anexo?')) {
        api.post(`${process.env.VUE_APP_API}/documento/remove-anexo/${id}`, '', {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
            .then(() => {
              this.$router.push('/documentos');
            })
            .catch((erro) => {
              this.showErro = true
              this.respErro.push(erro.response.data.errors);
            })
      }
    },

    close() {
      this.$router.push('/atos-normativos');
    },

    save() {
      this.carregando = true;
      let url = this.form.id ? 'update' : 'store';

      api.post(`${process.env.VUE_APP_API}/ato-normativo/${url}`, this.serialize(), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then((response) => {
            this.message = response.data.message;
            this.dialogConfirm = true
          })
          .catch((erro) => {
            if (erro.response.data.errors) {
              let erros = erro.response.data.errors

              this.showErro = true
              this.respErro = [];

              if (erros.anexo) {
                this.respErro.push(erros.anexo[0]);
              }

            } else if (erro.response.data.message) {
              let msgErro = erro.response.data.message;
              this.respErro.push(msgErro);
            }

          })
          .finally(() => this.carregando = false)
    },

    closeConfirm() {
      this.dialogConfirm = false
      this.$router.push('/atos-normativos')
    },

    buscarTiposAtosNormativos() {
      api.get(`${process.env.VUE_APP_API}/ato-normativo/tipos`)
          .then((r) => {
            this.tipos = r.data;
          });
    },
    buscarEmitentes() {
      api.get(`${process.env.VUE_APP_API}/ato-normativo/emitentes`)
          .then((r) => {
            this.emitentes = r.data;
          });
    }

  }
}
</script>

<style scoped>
ul {
  list-style: none;
}

</style>