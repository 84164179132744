<template>
  <v-card>
    <v-progress-linear
        :active="carregando"
        height="6"
        indeterminate
        rounded
    ></v-progress-linear>

    <h1 class="ml-4">
      Atos Normativos
      <v-btn class="d-none d-sm-flex mr-4 mt-3" color="blue darken-1" dark style="width: 110px; float: right"
             to="/atos-normativos/create">
        Cadastrar
      </v-btn>
    </h1>
    <br/>
    <v-form
        lazy-validation
        @submit.prevent
    >
      <v-row>
        <v-col class="ml-4 col-md-6">
          <v-text-field
              v-model="busca"
              append-icon="mdi-magnify"
              clearable
              label="Pesquise e tecle Enter..."
          ></v-text-field>
        </v-col>
        <v-col class="col-md-2">
          <v-btn style="display: none" type="submit" @click="initialize()">Pesquisar</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-card-text>
      <v-data-table
          :headers="colunas"
          :items="atos"
          light
      >
        <template v-slot:top>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h6">
                Você gostaria de deletar esse item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                >
                  Cancelar
                </v-btn>

                <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                >
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn class="edit" :to="'/atos-normativos/edit/'+item.id" icon>
            <v-icon
                class="mr-2"
                color="rgb(77 85 170)"
                small
            >
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-icon
              color="rgb(175 43 10)"
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          Nenhum registro encontrado
        </template>
        <template v-slot:item.anexo="{item}">
          <v-btn
              class="text-none ml-n5"
              color="primary"
              dark
              target="_blank"
              text
              :loading="carregando"
              @click="buscarAnexo(item.id)"
          >
            <v-icon color="red" x-large>mdi-file-pdf-box</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </v-card-text>

    <input id="area" style="color: white; border: none;" type="text" value="">
  </v-card>

</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      linhaSelecionada: '',
      dialogDelete: false,
      carregando: true,
      colunas: [
        {
          text: 'Número',
          value: 'numero_completo'
        },
        {
          text: 'Tipo',
          value: 'tipo',
        },
        {
          text: 'Assunto',
          value: 'assunto',
          width: 600,
        },
        {
          text: 'Emitente',
          value: 'emitente'
        },
        {
          text: 'Data Cadastro',
          value: 'dt_cadastro'
        },
        {
          text: 'Anexo',
          value: 'anexo'
        },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false
        },
      ],
      atos: [],
      busca: '',
    }
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    buscarAnexo(id) {
      this.carregando = true;
      api.get(`${process.env.VUE_APP_API}/ato-normativo/buscar-anexo/${id}`, {responseType: 'blob'})
          .then((r) => {
            let fileURL = URL.createObjectURL(r.data);
            window.open(fileURL);
          })
          .finally(() => {
            this.carregando = false;
          });
    },

    initialize() {
      let busca = encodeURIComponent(this.busca) ?? this.busca;
      api.get(`${process.env.VUE_APP_API}/ato-normativo/grid?busca=${busca}`)
          .then(r => {
            this.atos = r.data;
          })
          .finally(() => this.carregando = false)
    },

    editItem(i) {
      return `/ato-normativo/edit/${i.id}`;
    },

    deleteItem(item) {
      this.linhaSelecionada = item;
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      api.get(`${process.env.VUE_APP_API}/ato-normativo/delete/${this.linhaSelecionada.id}`)
          .then(() => {
            this.closeDelete()
            this.initialize()
          })
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
}
</script>

<style scoped>
.edit{
  width: 25px;
}
</style>