<template>
    <v-card>
      <v-progress-linear
        :active="editando"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>

      <h1 class="ml-4">{{ formTitle }}</h1>

      <v-card-text>
        <form id="form" enctype="application/x-www-form-urlencoded">
          
          <input type="hidden" v-model="form.id">

          <v-row>
            <v-col cols="10">
              <v-text-field
                  v-model="form.titulo"
                  :rules="obrigatorio"
                  label="Título *"
              ></v-text-field>  
            </v-col> 
          </v-row>

          <v-row>
            <v-col cols="10">
              <v-text-field
                  v-model="form.link"
                  :rules="obrigatorio"
                  label="Link do vídeo *"
              ></v-text-field>  
            </v-col> 
          </v-row>

          <v-row>
            <v-col class="col-4 col-md-2" style="font-size: 12px">
                <div class="mb-3" style="color: #1E88E5; font-weight: bold">Publicação</div>

                <v-checkbox
                v-model="form.publicado"
                :label="textoPublicacao"
                color="success" 
                :value="form.publicado ? form.publicado : ''"
                hide-details
              ></v-checkbox>
            </v-col>

            <v-col class="col-8 col-md-3" style="font-size: 12px">
              <div v-if="usr_cadastro" class="mb-3">
                <div class="mb-3" style="color: #1E88E5; font-weight: bold">Autoria</div>

                Escrito por:
                <b>{{usr_cadastro}}</b> <br />
                Em:
                <b>{{dt_cadastro}}</b>
              </div>

              <div v-if="usr_alteracao" class="mb-3">
                Alterado por:
                <b>{{usr_alteracao}}</b> <br />
                Em:
                <b>{{dt_alteracao}}</b>
              </div>

              <div v-if="dt_publicacao && form.publicado">
                Publicado por:
                <b>{{usr_publicacao}}</b> <br />
                Em:
                <b>{{dt_publicacao}}</b>
              </div>
            </v-col>
            
          </v-row>
        </form>
      </v-card-text>


      <v-row>
        <template v-if="showErro">
          <v-col cols="10">
            <v-alert
              type="error"
              elevation="2"
              prominent
              v-show="true">
                <ul>
                  <li v-for="(i, indice) in respErro" :key="indice">{{i}}</li>
                </ul>
              </v-alert>
          </v-col>
        </template>
      </v-row>

      <br />
      <v-card-actions class="pa-6">
        <v-row>
          <v-btn
          class="mr-1"
          color="blue darken-1"
          dark
          @click="save"
          :loading="this.carregando"
          >
          Salvar
          </v-btn>
    
          <v-btn
          color="red darken-1"
          dark
          @click="close"
          >
          Pesquisar
          </v-btn>
        </v-row>   
      </v-card-actions>    


      <v-dialog v-model="dialogConfirm" max-width="500px">
          <v-card>
            <v-card-title class="text-h6">
              {{message}}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1" 
                text 
                @click="closeConfirm"
              >
                Ok
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-card> 
</template>
<script>
  import api from '@/api'

export default{
  data() {
    return {
      dialogConfirm: false,
      message: '',
      
      imagem: '',
      form: {
        id : '',
        titulo: '',
        link: '',
        publicado: ''
      },
      editando: true,
      carregando: false,
      showErro: false,
      respErro: '',
      usr_cadastro: '',
      usr_alteracao: '',
      usr_publicacao: '',
      dt_cadastro: '',
      dt_alteracao: '',
      dt_publicacao: '',
      valid: false,      
      obrigatorio: [
        v => !!v || 'Este campo é obrigatório',
      ]
    }
  },

  computed: {
    formTitle () {
      return this.$route.params.id === undefined || null ? 'Cadastro de Vídeo' : 'Edição de Vídeo'
    },
    textoPublicacao() {
      if(this.form.id) {
        return this.form.publicado ? 'Publicado' : 'Não publicado'
      }else {
        return this.form.publicado ? 'Publicar' : 'Não publicar'
      }
    }
  },

  created() {
    let id = this.$route.params.id;
    if(id != undefined){
      api.get(`${process.env.VUE_APP_API}/video/edit/${id}`)//erro
        .then((r) => {
          this.form.id = r.data.id;
          this.form.titulo = r.data.titulo;
          this.form.link = r.data.link;
          this.form.publicado = r.data.publicado;

          this.usr_cadastro = r.data.usuario_cad;
          this.usr_alteracao = r.data.usuario_alt;
          this.usr_publicacao = r.data.usuario_publicacao;
          this.dt_cadastro = r.data.created_at;
          this.dt_alteracao = r.data.updated_at;
          this.dt_publicacao = r.data.dt_publicacao;
        })
        .finally(() => this.editando = false)
    }else {
      this.editando = false;
    }

  },

  methods:{
    serialize(){
      let form = document.getElementById('form');
      let formData = new FormData(form);

      for (let campo in this.form) {
          formData.append(campo, this.form[campo] ?? '');
      }

      return formData
    },

    close () {
      this.$router.push('/video');
    },

    save () {
      this.carregando = true;

      let url = this.form.id ? 'update' : 'store';

      api.post(`${process.env.VUE_APP_API}/video/${url}`, this.serialize(), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        this.message = response.data.message;
        this.dialogConfirm = true

      })
      .catch((erro) => {
        if(erro.response) {
          let erros = erro.response.data.errors

          this.showErro = true
          this.respErro = [];

          Object.keys(erros).forEach((key) => {
            this.respErro.push(erros[key][0]);
          })

        }
      })
      .finally(() => this.carregando = false)
    },

    closeConfirm () {
      this.dialogConfirm = false;
      this.$router.push('/video');
      /*
      if(!this.form.id){
        this.$router.push('/video');
      }else {
        window.location.reload();
      }*/
    },
  }
}
</script>