<template>
  <v-card>
    <v-progress-linear :active="editando" indeterminate rounded height="6"></v-progress-linear>

    <h1 class="ml-4">{{ formTitle }}</h1>

    <v-card-text>
      <form id="form" enctype="multipart/form-data">
        <input type="hidden" v-model="form.id">
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="form.titulo" :rules="obrigatorio" id="titulo" label="Titulo *"
              @keyup="setURL()"></v-text-field>
          </v-col>
        </v-row>

        <!--
            <v-row>
              <v-col>
                <v-btn
                  color="cyan"
                  elevation="2"
                  dark
                  right
                  fixed
                  @click="adicionarInputAnexo"
                >
                  <v-icon
                  >
                    mdi-plus
                  </v-icon>
                  Foto
                </v-btn>
              </v-col>
            </v-row>
            -->
        <div>
          <v-row v-for="(arquivo, indice) in form.galeriaArray" :key="indice">
            <v-col cols="6" md="6">
              <v-file-input md="6" @change="onFileChange()" multiple counter accept="image/*" v-model="arquivo.anexo"
                :label="'Adicione as fotos '">
              </v-file-input>

              <v-radio-group v-model="arquivo.capa">
                <div class="imgMostrar" v-for="(img, index) in arquivo.anexo" :key="index">
                  <img :src="gerarUrl(img)">
                  <v-text-field clearable id="legenda" v-model="arquivo.legenda[index]"
                    label="Adicione a legenda da foto">
                  </v-text-field>

                  <v-radio class="ml-7 radio-font" label="Usar como foto de capa">
                  </v-radio>
                </div>
              </v-radio-group>
            </v-col>
          </v-row>
        </div>

        <h2 v-show="form.fotosAnexo.length > 0" class="mb-5">Fotos salvas</h2>
        <v-row>
          <v-col v-for="(item, index) in form.fotosAnexo" :key="index" class="col-12 col-md-2"
            style="border: 1px solid #ccc">
            <v-img :src="item.anexo" height="200" class="mb-2 mr-2" style="display: inline-block;"></v-img>
            <div class="text-center">{{ item.titulo }}</div>
            <div class="text-center">
              <v-icon color="success" class="mr-3" title="Capa do Álbum" v-show="capa == item.id_foto">{{ 'mdi-decagram'
                }}</v-icon>

              <v-btn v-show="capa != item.id_foto" depressed color="primary" small class="mr-3"
                title="Tornar esta foto capa do Álbum" @click="changeCapa(item.id_foto)">
                <v-icon>{{ 'mdi-decagram-outline' }}</v-icon>
              </v-btn>

              <v-btn class="btn-sm" color="error" small title="Remover Foto" @click="removeFoto(item.id_foto, index)">
                <v-icon>{{ 'mdi-delete-circle' }}</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-12 col-md-6">
            <v-text-field id="url" v-model="form.url" :rules="obrigatorio"
              label="Crie um link para esta página"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4 col-md-2" style="font-size: 12px">
            <div class="mb-3" style="color: #1E88E5; font-weight: bold">
              Publicação
            </div>
            <v-checkbox v-model="form.publicado" :label="textoPublicacao" color="success"
              :value="form.publicado ? form.publicado : ''" hide-details></v-checkbox>
          </v-col>
          <v-col class="col-8 col-md-3" style="font-size: 12px">
            <div v-if="usr_cadastro" class="mb-3">
              <div class="mb-3" style="color: #1E88E5; font-weight: bold">
                Autoria
              </div>

              Escrito por:
              <b>{{ usr_cadastro }}</b> <br />
              Em:
              <b>{{ dt_cadastro }}</b>
            </div>

            <div v-if="usr_alteracao" class="mb-3">
              Alterado por:
              <b>{{ usr_alteracao }}</b> <br />
              Em:
              <b>{{ dt_alteracao }}</b>
            </div>

            <div v-if="dt_publicacao && form.publicado">
              Publicado por:
              <b>{{ usr_publicacao }}</b> <br />
              Em:
              <b>{{ dt_publicacao }}</b>
            </div>
          </v-col>

        </v-row>

      </form>
    </v-card-text>

    <v-row>
      <template v-if="showErro">
        <v-col cols="6">
          <v-alert colored-border type="error" elevation="2" class="ml-4" v-show="true">
            <ul>
              <li v-for="(i, indice) in respErro" :key="indice">
                {{ i }}
              </li>
            </ul>
          </v-alert>
        </v-col>
      </template>
    </v-row>

    <v-card-actions class="pa-6">
      <v-row>
        <v-btn class="mr-1" color="blue darken-1" dark @click="save" :loading="this.carregando">
          Salvar
        </v-btn>

        <v-btn color="red darken-1" dark @click="close">
          Pesquisar
        </v-btn>
      </v-row>
    </v-card-actions>

    <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">
          {{ message }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeConfirm">
            Ok
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import api from '@/api'

export default {
  name: "Cadastro",
  data() {
    return {
      dialogConfirm: false,
      message: '',
      imagem: '',
      form: {
        previewImage: '1',
        id: '',
        titulo: '',
        mime: '',
        publicado: '',
        url: 'galeria/',
        galeriaArray: [
          {
            anexo: null,
            capa: 0,
            legenda: []
          }
        ],
        fotosAnexo: [],
      },
      editando: true,
      carregando: false,
      showErro: false,
      respErro: '',
      usr_cadastro: '',
      usr_alteracao: '',
      usr_publicacao: '',
      dt_cadastro: '',
      dt_alteracao: '',
      dt_publicacao: '',
      capa: '',
      valid: false,
      obrigatorio: [
        v => !!v || 'Este campo é obrigatório',
      ],
      regras: [
        v => !v || v.size < 10000000 || 'Avatar size should be less than 2 MB!',
      ],
    }
  },

  computed: {
    formTitle() {
      return this.$route.params.id === undefined || null ? 'Cadastro de Galeria' : 'Edição de Galeria'
    },
    textoPublicacao() {
      if (this.form.id) {
        return this.form.publicado ? 'Publicado' : 'Não publicado'
      } else {
        return this.form.publicado ? 'Publicar' : 'Não publicar'
      }
    },
  },

  created() {
    let id = this.$route.params.id;
    if (id != undefined) {
      api.get(`${process.env.VUE_APP_API}/galeria-de-fotos/edit/${id}`)
        .then((r) => {
          //console.log(r.data.length);
          this.form.id = r.data.id;
          this.form.titulo = r.data.titulo;
          this.form.publicado = r.data.publicado;
          this.form.url = r.data.url;

          for (let i = 0; i < r.data.fotosGaleria.length; i++) {
            this.form.fotosAnexo[i] = {
              'id_foto': r.data.fotosGaleria[i].foto_id,
              'anexo': `data:${r.data.fotosGaleria[i].foto_mime};base64, ${r.data.fotosGaleria[i].foto_anexo}`,
              'titulo': r.data.fotosGaleria[i].foto_titulo,
              'capa': r.data.fotosGaleria[i].capa
            };

            if (r.data.fotosGaleria[i].capa) {
              this.capa = r.data.fotosGaleria[i].foto_id;
            }

          }

          this.usr_cadastro = r.data.usuario_cad;
          this.usr_alteracao = r.data.usuario_alt;
          this.usr_publicacao = r.data.usuario_publicacao;
          this.dt_cadastro = r.data.created_at;
          this.dt_alteracao = r.data.updated_at;
          this.dt_publicacao = r.data.dt_publicacao;
        })
        .finally(() => this.editando = false)
    } else {
      this.form.id = '';
      this.form.titulo = '';
      this.form.galeriaArray.anexo = [];
      this.form.galeriaArray.capa = [],
        this.form.galeriaArray.legenda = [];

      this.editando = false;
    }
  },

  methods: {
    gerarUrl(texto) {
      return URL.createObjectURL(texto);
    },

    onFileChange() {
      const foto = document.querySelectorAll('input[type="file"]:not([id="foto"])')[0].files;
      this.aImagens = foto;
    },

    adicionarInputAnexo() {
      this.form.galeriaArray.push({ anexo: null, capa: 0, legenda: [] })
    },

    serialize() {
      let form = document.getElementById('form');
      let formData = new FormData(form);

      for (let campo in this.form) {
        formData.append(campo, this.form[campo] ?? '');
      }

      let galeriaArray = this.form.galeriaArray[0]

      if (galeriaArray.anexo) {
        for (let i = 0; i < galeriaArray.anexo.length; i++) {

          let arquivo = galeriaArray.anexo[i]
          if (typeof arquivo !== 'undefined') {
            formData.append('anexo[]', arquivo);
          }

          let legenda = galeriaArray.legenda[i]
          if (typeof legenda !== 'undefined') {
            formData.append('legenda[]', legenda);
          }
        }
      }

      let capa = galeriaArray.capa
      formData.append('capa', capa);

      return formData

    },

    close() {
      this.$router.push('/galeria-de-fotos');
    },

    changeCapa(id) {
      if (confirm('Deseja realmente mudar a foto de capa?')) {
        api.post(`${process.env.VUE_APP_API}/galeria-de-fotos/change-capa/${id}`, '', {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            this.capa = id;
            alert(response.data.message);
          })
          .catch((erro) => {
            this.showErro = true
            this.respErro.push(erro.response.data.errors);
          })
      }
    },

    removeFoto(id, index) {

      if (confirm('Deseja realmente remover esta foto?')) {
        api.delete(`${process.env.VUE_APP_API}/galeria-de-fotos/delete-foto/${id}`, '', {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            this.form.fotosAnexo.splice(index, 1);
            alert(response.data.message);
          })
          .catch((erro) => {
            this.showErro = true
            this.respErro.push(erro.response.data.errors);
          })
      }
    },

    save() {
      this.carregando = true;

      let url = this.form.id ? 'update' : 'store';

      api.post(`${process.env.VUE_APP_API}/galeria-de-fotos/${url}`, this.serialize(), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          this.message = response.data.message;
          this.dialogConfirm = true

        })
        .catch((erro) => {
          if (erro.response) {
            let erros = erro.response.data.errors
            this.showErro = true
            this.respErro = [];

            if (typeof erros.titulo !== 'undefined')
              this.respErro.push(erros.titulo);

            if (typeof erros.descricao !== 'undefined')
              this.respErro.push(erros.descricao);
          }
        })
        .finally(() => this.carregando = false)
    },

    closeConfirm() {
      this.dialogConfirm = false
      //this.$router.push('/galeria-de-fotos')
    },

    setURL() {
      let texto = this.removeAcento(this.form.titulo);
      texto = texto.replace(/( )+/g, '-');
      this.form.url = `galeria/${texto}`;
    },

    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
      text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
      text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
      text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
      text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
      text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
      return text;
    }
  }
}
</script>

<style>
#imagemM {
  width: 230px;
  height: 250px;
  border-radius: 5px;
}

ul {
  list-style: none;
}

#descricao {
  height: 200px;
}

.imgMostrar {
  display: flex;
  margin-bottom: 5%;
}

.imgMostrar img {
  border-radius: 15px;
  width: 150px;
  height: 100px;
  margin-right: 5%;
}

.radio-font label {
  font-size: 15px;
}
</style>