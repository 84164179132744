<template>
  <v-card>

    <h1 class="ml-4">Editar Categoria</h1>

    <v-card-text>
      <form id="form">
        <v-row>
          <v-col class="col-12 col-md-6">
            <v-text-field
              v-model="form.nome"
              label="Nome *"
            ></v-text-field>
          </v-col>

          <v-col class="col-12 col-md-4" style="font-size: 12px">
            <v-switch
              v-model="form.ativa"
              :label="form.ativa ? 'Ativa' : 'Inativa'"
            ></v-switch>
          </v-col>
        </v-row>
      </form>
    </v-card-text>

    <br />
    <v-card-actions class="pa-6">
      <v-row>
        <v-btn
          class="mr-1"
          color="blue darken-1"
          dark
          @click="save"
        >
          Salvar
        </v-btn>

        <v-btn color="red darken-1" dark @click="close"> Pesquisar </v-btn>
      </v-row>
    </v-card-actions>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">
          {{ message }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"> Ok </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </v-card>
</template>
<script>
import api from "@/api";

export default {
  data() {
    return {
      dialog: false,
      message: '',
      form: {
        id: this.$route.params.id,
        nome: "",
        ativa: ""
      }
    }
  },

  created() {
    this.getCategoria()
  },

  methods: {
    getCategoria(){
      api
      .get(`${process.env.VUE_APP_API}/categoria/edit/${this.form.id}`)
      .then((r) => {
        this.form.nome = r.data[0].nome;
        this.form.ativa = r.data[0].ativa;
      })
    },

    close() {
      this.$router.push("/categorias");
    },

    save() {
      api
        .post(`${process.env.VUE_APP_API}/categoria/update`,this.form)
        .then((response) => {
          this.message = 'Atualização ok';
          this.dialog = true;
          console.log(response.data)
        })
        .catch((erro) => {
          this.close(erro);
        })
    },

    closeConfirm() {
      this.dialogConfirm = false;
      if (!this.form.id) {
        this.$router.push("/noticia");
      } else {
        this.$router.push("/noticia");
      }
    },
  },
};
</script>

<style>
#imagemM {
  /* float: right; */
  width: 230px;
  height: 250px;
  border-radius: 5px;
}

ul {
  list-style: none;
}
</style>
