<template>
    <v-card>
      <v-progress-linear
        :active="editando"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>

      <h1 class="ml-4">{{ formTitle }}</h1>

      <v-card-text>
        <form 
          id="form" 
          enctype="application/x-www-form-urlencoded"
        >
          
          <input 
            type="hidden" 
            v-model="form.id"
          >
            <v-row>
              <v-col class="col-12 col-md-6">
                <v-text-field
                  v-model="form.titulo"
                  :rules="obrigatorio"
                  label="Titulo *"
                  @keyup="setURL()"
                ></v-text-field>  
              </v-col>
            </v-row>

            <v-row>
              <v-col 
                class="col-12 col-md-6"
              >
                <v-file-input  
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  label="Adicione uma foto de capa"
                  v-model="form.foto"
                  :rules="regras"
                  id="foto"
                ></v-file-input>
                <!-- <p>Tamanho máximo: 10M</p> -->
                <div 
                  v-show="imagem"
                >
                  <img 
                    :src="imagem" 
                    id="imagemM"
                  >
                </div>

              </v-col>             
            </v-row>

            <v-row>
              <v-col class="col-12 col-md-6">
                  <label for="descricao">Descrição *</label>
                  <tiptap v-model="form.descricao" />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-12 col-md-6" style="">
                <v-text-field
                  label="URL amigável"
                  v-model="form.url"
                  :rules="obrigatorio"
                  placeholder="Crie um link para esta página"
                ></v-text-field>
              </v-col>
            </v-row>

            <div>
              <v-row
                v-for="(arquivo, indice) in form.anexosArray"
                :key="indice"
              >
                <v-col 
                  class="col-12 col-md-3"
                >
                  <v-file-input
                    accept="pdf/*"
                    chips
                    class="arquivoAnexo"
                    v-model="arquivo.anexo"
                    :label="'Adicione o '+(indice+1) +'°'+ ' anexo '"
                  >
                  </v-file-input>
                </v-col>
                <v-col 
                  class="col-12 col-md-3"
                >
                  <v-text-field
                    clearable
                    id="anexoTitulo"
                    v-model="arquivo.anexoTitulo"
                    :label="'Adicione o titulo do '+(indice+1) +'°'+' anexo'"
                    prepend-icon="mdi-comment"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col>
                <v-btn
                  class="mx-1"
                  color="cyan"
                  dark
                  @click="adicionarInputAnexo"
                >
                  <v-icon
                  >
                    mdi-plus
                  </v-icon>
                  Anexo
                </v-btn>
              </v-col>
            </v-row>

            <div v-show="anexos.length > 0" class="mb-5 col-md-6">
              <h3 class="mt-5">Arquivos anexados</h3>
              <hr>
              <v-list-item
                v-for="(item, indice) in anexos"
                :key="indice"
                :id="item.id"
                style="padding: 0;">
                <v-list-item-content>
                  <v-list-item-title style="cursor: pointer; color: blue;">
                      <v-icon x-large color="red" class="mr-0">{{ 'mdi-file-pdf-box' }}</v-icon>{{item.titulo}}
                      <span class="ml-5" style="color: red;" @click="removeAnexo(item.id)"> X </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <hr>
            </div>

            <v-row>
              <v-col class="col-4 col-md-2" style="font-size: 12px">
                <div class="mb-3" style="color: #1e88e5; font-weight: bold">
                  Publicação
                </div>

                <v-checkbox
                  v-model="form.publicado"
                  :label="textoPublicacao"
                  color="success"
                  :value="form.publicado ? form.publicado : ''"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col class="col-8 col-md-3" style="font-size: 12px">
                <div v-if="usr_cadastro" class="mb-3">
                  <div class="mb-3" style="color: #1E88E5; font-weight: bold">Autoria</div>

                  Escrito por:
                  <b>{{usr_cadastro}}</b> <br />
                  Em:
                  <b>{{dt_cadastro}}</b>
                </div>

                <div v-if="usr_alteracao" class="mb-3">
                  Alterado por:
                  <b>{{usr_alteracao}}</b> <br />
                  Em:
                  <b>{{dt_alteracao}}</b>
                </div>

                <div v-if="dt_publicacao && form.publicado">
                  Publicado por:
                  <b>{{ usr_publicacao }}</b> <br />
                  Em:
                  <b>{{ dt_publicacao }}</b>
                </div>
              </v-col>
            </v-row>
        </form>
      </v-card-text>

      <v-row>
        <template 
          v-if="showErro"
        >
          <v-col 
            cols="8"
          >
            <v-alert
              type="error"
              elevation="2"
              class="ml-4"
              v-show="true"
            >
              <ul>
                <li 
                  v-for="(i, indice) in respErro" 
                  :key="indice"
                >
                  {{i}}
                </li>
              </ul>
            </v-alert>
          </v-col>
        </template>
      </v-row>
       
      <v-card-actions 
        class="pa-6"
      >
        <v-row>
          <v-btn
            class="mr-1"
            color="blue darken-1"
            dark
            @click="save"
            :loading="this.carregando"
          >
            Salvar
          </v-btn>
    
          <v-btn
            color="red darken-1"
            dark
            @click="close"
          >
            Pesquisar
          </v-btn>
        </v-row>   
      </v-card-actions>    
      <v-dialog 
        v-model="dialogConfirm" 
        max-width="500px"
      >
        <v-card>
          <v-card-title 
            class="text-h6"
          >
            {{message ? message : 'Registro salvo com sucesso!'}}
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1" 
              text 
              @click="closeConfirm"
            >
              Ok
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
    </v-card> 
</template>
<script>
  import api from "@/api";
  import Tiptap from "../../components/Tiptap.vue"
  export default{
    data() {
      return {
        dialogConfirm: false,
        message: '',
        imagem: '',
        anexos: [],
        form: {
          id : '',
          titulo: '',
          foto: null,
          descricao: '',
          publicado: "",
          mime: '',
          anexosArray: [
            { anexo: null, anexoTitulo: ''}
          ],
          url: 'pagina/'
        },
        editando: true,
        carregando: false,
        showErro: false,
        respErro: '',
        usr_cadastro: '',
        usr_alteracao: '',
        usr_publicacao: "",
        dt_cadastro: '',
        dt_publicacao: "",
        dt_alteracao: '',
        valid: false,      
        obrigatorio: [
          v => !!v || 'Este campo é obrigatório',
        ],
        regras: [
          v => !v || v.size < 10000000 || 'Avatar size should be less than 2 MB!',
        ],
      }
    },
    components: {
      Tiptap
    },

    computed: {
      formTitle () {
        return this.$route.params.id === undefined || null ? 'Cadastro de Página' : 'Edição de Página'
      },
      textoPublicacao() {
        return this.form.publicado ? "Publicar" : "Não publicar";
      }
    },
    created() {
      let id = this.$route.params.id;
      if(id != undefined){
        api.get(`${process.env.VUE_APP_API}/pagina/edit/${id}`)//erro
        .then((r) => {
          this.form.id = r.data.id;
          this.form.titulo = r.data.titulo;
          this.form.foto = null;
          this.form.descricao = r.data.descricao;
          this.form.url = r.data.url;
          this.form.publicado = r.data.publicado;

          if(r.data.foto!=null){
            this.imagem = `data:${r.data.mime};base64, ${r.data.foto}`;
          }

          this.usr_cadastro = r.data.usuario_cad;
          this.usr_alteracao = r.data.usuario_alt;
          this.usr_publicacao = r.data.usuario_publicacao;
          this.dt_cadastro = r.data.created_at;
          this.dt_alteracao = r.data.updated_at;
          this.dt_publicacao = r.data.dt_publicacao;

          if(r.data.anexos!=null){

            r.data.anexos.forEach((anexo, i) => {
              this.anexos[i] = {
                'id': anexo.id,
                'titulo': anexo.titulo,
                'anexo': `data:${anexo.type};base64, ${anexo.anexo}`
              }
            });
          }
        })
        .finally(() => this.editando = false)
        
      }else{
        this.form.id = '';
        this.form.titulo = '';
        this.form.foto = null;
        this.form.descricao = '';
        this.form.anexosArray.anexo = '';
        this.form.anexosArray.anexoTitulo = '';

        this.editando = false;
      }    
    },

    methods:{
      adicionarInputAnexo(){
        this.form.anexosArray.push({anexo: null, descricao: ''})
      },

      serialize(){
        let form = document.getElementById('form');
        let formData = new FormData(form);

        for (let campo in this.form) {
            formData.append(campo, this.form[campo] ?? '');
        }

        let uploads = document.querySelectorAll('input[type="file"]:not([id="foto"])');

        uploads.forEach(function(item){
          let arquivo = item.files[0]
          if (typeof arquivo !== 'undefined') {
            formData.append('anexo[]', arquivo);
          }
        })
          
        let aTitulo =  document.querySelectorAll('input[type="text"]#anexoTitulo')

        aTitulo.forEach(function(item){
          let titulo = item.value;

          if (typeof titulo !== 'undefined') {
            formData.append('anexoTitulo[]', titulo);
          }
        })

        let fotoInput = document.querySelector('#foto');
        let foto = fotoInput.files[0]; 
        if (typeof foto !== 'undefined') {
          formData.append('foto', foto);
        }

        return formData
      },

      removeAnexo(id) {
        if(confirm('Deseja realmente remover este anexo?')){
          api.post(`${process.env.VUE_APP_API}/pagina/remove-anexo/${id}`, '', {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(() => {
            document.getElementById(id).remove()
          })
          .catch((erro) => {          
              this.showErro = true
              this.respErro.push(erro.response.data.errors);
          })
        }
      },

      setURL() {
        let texto = this.removeAcento(this.form.titulo);
        texto = texto.replace(/( )+/g, '-');
        this.form.url = `pagina/${texto}`;
      },
      close () {
        this.$router.push('/pagina');
      },

      save () {
        this.carregando = true;

        let url = this.form.id ? 'update' : 'store';

        api.post(`${process.env.VUE_APP_API}/pagina/${url}`, this.serialize(), {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          this.message = response.data.message;
          this.dialogConfirm = true
        })
        .catch((erro) => {
          this.showErro = true;

          console.log(erro.response.data)
          this.respErro = [];

          if(erro.response.data.errors) {
            let erros = erro.response.data.errors
            

            if(erros.titulo) {
              this.respErro.push(erros.titulo[0]);
            }

            if(erros.descricao) {
              this.respErro.push(erros.descricao[0]);
            }

            if(erros.anexo) {
              this.respErro.push(erros.anexo[0]);
            }

            if(erros.url) {
              this.respErro.push(erros.url[0]);
            }
          }
          else if(erro.response.data.message) {
            console.log(erro.response.data.message);
            this.respErro.push(erro.response.data.message);
          }
        })
        .finally(() => this.carregando = false)
      },

      closeConfirm () {
        this.dialogConfirm = false
        this.$router.push('/pagina')
      },

      removeAcento (text){       
        text = text.toLowerCase();                                                         
        text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
        text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
        text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
        text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
        text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
        text = text.replace(new RegExp('[Ç]','gi'), 'c');
        return text;          
      }

    },
  }
</script>

<style>
  #imagemM{
    /* float: right; */
    width: 230px;
    height: 250px;
    border-radius: 5px; 
  }

  ul{
    list-style: none;
  }

  #descricao {
    height: 200px;
  }
</style>