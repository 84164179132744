<template>
  <div v-if="editor" class="container">
    <div class="button-group">
      <button type="button" class="btn" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        <v-icon>
          mdi-format-bold
        </v-icon>
      </button>
      <button type="button" class="btn" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        <v-icon>
          mdi-format-italic
        </v-icon>
      </button>
      <button type="button" class="btn" @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
        <v-icon>
          mdi-format-underline
        </v-icon>
      </button>
      <button type="button" class="btn" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        H1
      </button>
      <button type="button" class="btn" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        H2
      </button>
      <button type="button" class="btn" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        H3
      </button>
      <button type="button" class="btn" @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
        <v-icon>
          mdi-format-align-left
        </v-icon>
      </button>
      <button type="button" class="btn" @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
        <v-icon>
          mdi-format-align-center
        </v-icon>
      </button>
      <button type="button" class="btn" @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
        <v-icon>
          mdi-format-align-right
        </v-icon>
      </button>
      <button type="button" class="btn" @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
        <v-icon>
          mdi-format-align-justify
        </v-icon>
      </button>
      <button type="button" class="btn" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        <v-icon>
          mdi-format-list-bulleted
        </v-icon>
      </button>
      <button type="button" class="btn" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        <v-icon>
          mdi-format-list-numbered
        </v-icon>
      </button>
      <button type="button" class="btn" @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
        <v-icon>
          mdi-link
        </v-icon>
      </button>
      <button type="button" class="btn" @click="dialog1 = true">
        <v-icon>
          mdi-image
        </v-icon>
      </button>
    </div>

    <editor-content :editor="editor" />

    <!-- modal que mostra as imagens -->
    <v-dialog v-model="dialog1" hide-overlay transition="scale-transition" max-width="700">
      <v-card>
        <v-toolbar dark color="dark">
          <v-toolbar-title>Inserir imagens</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog1 = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar por nome" single-line
            hide-details></v-text-field>
          <v-btn depressed color="primary" class="ml-10" @click="toggleDialog">
            Adicionar
          </v-btn>
        </v-card-title>

        <div style="display: flex; flex-wrap: wrap;">
          <v-col v-for="imagem in imagens" :key="imagem.titulo" class="d-flex child-flex" cols="4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-img :src="imagem.url" :lazy-src="imagem.url" aspect-ratio="1" class="grey lighten-2"
                  @click="addImage(imagem.url)" v-bind="attrs" v-on="on">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </template>
              <span>{{ imagem.titulo }}</span>
            </v-tooltip>
          </v-col>
        </div>
      </v-card>
    </v-dialog>

    <!-- modal do upload -->
    <v-dialog v-model="dialog2" hide-overlay transition="scale-transition" max-width="600">
      <v-toolbar dark color="dark">
        <v-toolbar-title>Adicionar imagens</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="toggleDialog">
          <v-icon>mdi-undo</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-progress-linear :active="carregando" indeterminate rounded height="6"></v-progress-linear>

        <v-card-text>
          <form id="formimage" enctype="application/x-www-form-urlencoded">
            <input type="hidden" v-model="form.id">
            <v-row>
              <v-col>
                <v-text-field v-model="form.titulo" :rules="obrigatorio" label="Titulo da imagem *"
                  @keyup="setURL()"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-file-input chips label="Buscar imagem *">
                </v-file-input>
              </v-col>
            </v-row>
          </form>
        </v-card-text>

        <v-row>
          <template v-if="showErro">
            <v-col cols="6">
              <v-alert type="error" elevation="2" class="ml-4" v-show="true">
                <ul>
                  <li v-for="(i, indice) in respErro" :key="indice" v-html="i">
                  </li>
                </ul>
              </v-alert>
            </v-col>
          </template>
        </v-row>

        <v-card-actions class="pa-6">
          <v-row>
            <v-btn class="mr-1" color="blue" dark @click="save" :loading="this.carregando">
              Salvar
            </v-btn>
          </v-row>
        </v-card-actions>
        <v-dialog v-model="dialogConfirm" max-width="500px">
          <v-card>
            <v-card-title class="text-h6">
              {{ message ? message : 'Registro salvo com sucesso!' }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirm">
                Ok
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Heading from '@tiptap/extension-heading'
import TextAlign from '@tiptap/extension-text-align'
import ListItem from '@tiptap/extension-list-item'
import Text from '@tiptap/extension-text'
import Paragraph from '@tiptap/extension-paragraph'
import Document from '@tiptap/extension-document'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import Placeholder from '@tiptap/extension-placeholder'
import { Editor, EditorContent } from '@tiptap/vue-2'
import api from '@/api'

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dialog1: false,
      dialog2: false,
      search: '',
      editor: null,
      colunas: [
        {
          text: 'Imagem',
          value: 'type'
        },
        {
          text: 'Nome',
          value: 'nome'
        },
        {
          text: 'Ação',
          value: 'url',
          sortable: false
        },
      ],
      servicos: [],
      titulo: '',
      imagens: [],
      dialogConfirm: false,
      message: '',
      imagem: '',
      anexo: false,
      form: {
        id: '',
        titulo: '',
        url: 'documento/'
      },
      color: '',
      carregando: false,
      showErro: false,
      respErro: [],
      obrigatorio: [
        v => !!v || 'Este campo é obrigatório',
      ],
    }
  },

  methods: {
    addImage(url) {
      this.editor.chain().focus().setImage({ src: url }).run();
    },

    toggleDialog() {
      this.dialog1 = !this.dialog1;
      this.dialog2 = !this.dialog2;
    },

    initialize() {
      let titulo = encodeURIComponent(this.titulo);
      api.get(`${process.env.VUE_APP_API}/documento/grid?titulo=${titulo}`)
        .then(r => {
          this.servicos = r.data;
        })
        .finally(() => this.carregando = false)
    },

    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
      text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
      text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
      text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
      text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
      text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
      return text;
    },

    setURL() {
      let texto = this.removeAcento(this.form.titulo);
      texto = texto.replace(/( )+/g, '-');
      this.form.url = `documento/${texto}`;
    },

    serialize() {
      let form = document.getElementById('formimage');
      let formData = new FormData(form);

      for (let campo in this.form) {
        formData.append(campo, this.form[campo] ?? '');
      }

      let uploads = document.querySelectorAll('input[type="file"]');

      uploads.forEach(function (item) {
        let arquivo = item.files[0]
        if (typeof arquivo !== 'undefined') {
          formData.append('anexo', arquivo);
        }
      });


      return formData
    },

    save() {
      this.carregando = true;

      let url = this.form.id ? 'update' : 'store';

      api.post(`${process.env.VUE_APP_API}/documento/${url}`, this.serialize(), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          this.message = response.data.message;
          this.dialogConfirm = true;
        })
        .catch((erro) => {
          if (erro.response.data.errors) {
            let erros = erro.response.data.errors

            this.respErro = [];

            if (erros.titulo) {
              this.respErro.push(erros.titulo[0]);
            }

            if (erros.anexo) {
              this.respErro.push(erros.anexo[0]);
            }

            if (erros.url) {
              this.respErro.push(erros.url[0]);
            }
          }
          else if (erro.response.data.message) {
            let msgErro = erro.response.data.message;
            this.respErro.push(msgErro);
          }
        })
        .finally(() => this.carregando = false)
    },

    closeConfirm() {
      this.dialogConfirm = false
      this.initialize()
      this.toggleDialog()
    },

    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },

  },

  watch: {
    value(value) {
      this.editor.getHTML() === value

      this.editor.commands.setContent(value, false)
    },

    servicos() {
      this.imagens = this.servicos.filter(servico => servico.type.startsWith("image/"));
    }
  },

  mounted() {
    this.editor = new Editor({
      editorProps: {
        attributes: {
          class: 'editor',
        },
      },
      extensions: [
        Bold,
        Italic,
        Underline,
        Heading.configure({
          levels: [1, 2, 3],
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        ListItem,
        Text,
        Paragraph.configure({
          HTMLAttributes: {
            class: 'editor',
          },
        }),
        Document,
        StarterKit,
        Link.configure({
          openOnClick: false,
          defaultProtocol: 'https',
        }),
        Image.configure({
          HTMLAttributes: {
            class: 'image',
          },
        }),
        Placeholder.configure({
          placeholder: 'Descrição',
        }),
      ],
      content: this.value,
      autofocus: true,
      editable: true,
      injectCSS: false,
      onUpdate: () => {
        this.editor.getHTML();
        this.$emit('input', this.editor.getHTML());
      },
    })
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style>
.btn {
  height: 32px;
  margin: 0.25rem;
  padding: 0 0.25rem;
  font-size: 1.25rem;
  font-weight: 500;
  background-color: #cdcdcd;
  border-radius: 5px;
}

.editor {
  height: 300px;
  padding: 1rem;
  overflow-y: auto;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #5e5e5e;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.image {
  max-width: 500px;
}

.mini {
  width: 70px;
  margin-top: 7px;
}
</style>