<template>
  <v-card>
    <v-progress-linear
        :active="editando"
        indeterminate
        rounded
        height="6"
    ></v-progress-linear>


    <h1 class="ml-4">{{ formTitle }}</h1>

    <v-card-text>
      <form id="form" enctype="application/x-www-form-urlencoded">

        <input type="hidden" v-model="form.id">
        <v-row>
          <v-col class="col-12 col-md-8">
            <v-text-field
                v-model="form.sigla"
                :rules="obrigatorio"
                label="Sigla *"
            ></v-text-field>
          </v-col>
          <v-col class="col-12 col-md-8">
            <v-text-field
                v-model="form.nomeDaUnidade"
                :rules="obrigatorio"
                label="Nome da Unidade *"
                @keyup="setURL()"
            ></v-text-field>
          </v-col>
          <v-col class="col-12 col-md-8">
            <v-autocomplete
                v-model="form.institucionalPai"
                :items="itemsInstitucionalPai"
                item-text="titulo"
                item-value="id"
                label="Unidade pai"
            ></v-autocomplete>
          </v-col>

          <v-col class="col-12 col-md-8">
            <label for="descricao">Descrição *</label>
            <tiptap v-model="form.descricao" />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-12 col-md-4" style="font-size: 12px">
            <div style="color: #1E88E5; font-weight: bold">URL amigável *</div>

            <v-text-field
                v-model="form.url"
                :rules="obrigatorio"
                placeholder="Crie um link para esta página"
                style="width: 90%"
            ></v-text-field>
          </v-col>
          <v-col class="col-12 col-md-2" style="font-size: 12px">
            <div style="color: #1E88E5; font-weight: bold">Ordem *</div>

            <v-text-field
                v-model="form.ordem"
                :rules="obrigatorio"
                placeholder="Visualização no site"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-12 col-md-4" style="font-size: 12px">
            <div style="color: #1E88E5; font-weight: bold">Anexar imagem</div>

            <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                label="Adicione uma foto de capa"
                v-model="form.foto"
                :rules="regras"
                id="foto"
                style="width: 90%"
            ></v-file-input>
            <span>
                  O arquivos deve ter até <b>10 MB</b>.<br />
                  Tipos de arquivos permitidos: <b>png, gif, jpg e jpeg</b >.
                </span>

          </v-col>

          <v-col class="col-12 col-md-2" style="font-size: 12px">
            <div class="mb-3" style="color: #1E88E5; font-weight: bold">Publicação</div>

            <v-checkbox
                v-model="form.publicado"
                :label="textoPublicacao"
                color="success"
                :value="form.publicado ? form.publicado : ''"
                hide-details
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-12 col-md-4">
            <!-- <p>Tamanho máximo: 10M</p> -->
            <div v-show="imagem">
              <img :src="imagem" id="imagemM">
            </div>
          </v-col>
          <v-col class="col-8 col-md-3" style="font-size: 12px">
            <div v-if="usr_cadastro" class="mb-3">
              <div class="mb-3" style="color: #1E88E5; font-weight: bold">Autoria</div>

              Escrito por:
              <b>{{usr_cadastro}}</b> <br />
              Em:
              <b>{{dt_cadastro}}</b>
            </div>

            <div v-if="usr_alteracao" class="mb-3">
              Alterado por:
              <b>{{usr_alteracao}}</b> <br />
              Em:
              <b>{{dt_alteracao}}</b>
            </div>

            <div v-if="dt_publicacao && form.publicado">
              Publicado por:
              <b>{{usr_publicacao}}</b> <br />
              Em:
              <b>{{dt_publicacao}}</b>
            </div>
          </v-col>
        </v-row>
      </form>
    </v-card-text>


    <v-row>
      <template v-if="showErro">
        <v-col cols="10">
          <v-alert
              type="error"
              elevation="2"
              prominent
              v-show="true">
            <ul>
              <li v-for="(i, indice) in respErro" :key="indice">{{i}}</li>
            </ul>
          </v-alert>
        </v-col>
      </template>
    </v-row>

    <br />
    <v-card-actions class="pa-6">
      <v-row>
        <v-btn
            class="mr-1"
            color="blue darken-1"
            dark
            @click="save"
            :loading="this.carregando"
        >
          Salvar
        </v-btn>

        <v-btn
            color="red darken-1"
            dark
            @click="close"
        >
          Pesquisar
        </v-btn>
      </v-row>
    </v-card-actions>
    <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">
          {{message}}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeConfirm"
          >
            Ok
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import api from '@/api'
import Tiptap from '../../components/Tiptap.vue';

export default{
  data() {
    return {
      dialogConfirm: false,
      message: '',
      itemsInstitucionalPai: [],
      imagem: '',
      form: {
        id : '',
        sigla: '',
        institucionalPai: '',
        titulo: '',
        foto: null,
        descricao: '',
        mime: '',
        publicado: '',
        ordem: '',
        url: 'institucional'
      },
      editando: true,
      carregando: false,
      showErro: false,
      respErro: '',
      usr_cadastro: '',
      usr_alteracao: '',
      usr_publicacao: '',
      dt_cadastro: '',
      dt_alteracao: '',
      dt_publicacao: '',
      valid: false,
      obrigatorio: [
        v => !!v || 'Este campo é obrigatório',
      ],
      regras: [
        v => !v || v.size < 10000000 || 'Avatar size should be less than 2 MB!',
      ],
    }
  },
  components: {
    Tiptap
  },

  computed: {
    formTitle () {
      return this.$route.params.id === undefined || null ? 'Cadastro Institucional' : 'Edição Institucional'
    },
    textoPublicacao() {
      if(this.form.id) {
        return this.form.publicado ? 'Publicado' : 'Não publicado'
      }else {
        return this.form.publicado ? 'Publicar' : 'Não publicar'
      }
    }
  },

  created() {
    let id = this.$route.params.id;
    if(id != undefined){
      api.get(`${process.env.VUE_APP_API}/institucional/edit/${id}`)//erro
          .then((r) => {
            this.form.id = r.data.id;
            this.form.sigla = r.data.nome_institucional;
            this.form.institucionalPai = r.data.fk_institucional_pai;
            this.form.nomeDaUnidade = r.data.titulo;
            this.form.foto = null;
            this.form.descricao = r.data.descricao;
            this.form.publicado = r.data.publicado;
            this.form.ordem = r.data.ordem;
            this.form.url = r.data.url;

            if(r.data.foto!=null){
              this.imagem = `data:${r.data.mime};base64, ${r.data.foto}`;
            }
            this.usr_cadastro = r.data.usuario_cad;
            this.usr_alteracao = r.data.usuario_alt;
            this.usr_publicacao = r.data.usuario_publicacao;
            this.dt_cadastro = r.data.created_at;
            this.dt_alteracao = r.data.updated_at;
            this.dt_publicacao = r.data.dt_publicacao;
          })
          .finally(() => this.editando = false)
    }else {
      this.editando = false;
    }

    this.comboUnidadesPai();
  },

  methods:{
    serialize(){
      let form = document.getElementById('form');
      let formData = new FormData(form);

      for (let campo in this.form) {
        formData.append(campo, this.form[campo] ?? '');
      }

      let fotoInput = document.querySelector('#foto');
      let arquivo = fotoInput.files[0];
      if (typeof arquivo !== 'undefined') {
        formData.append('foto', arquivo);
      }
      return formData
    },

    setURL() {
      let texto = this.removeAcento(this.form.nomeDaUnidade);
      texto = texto.replace(/( )+/g, '-');
      this.form.url = `institucional/${texto}`;
    },

    comboUnidadesPai() {
      api.get(`${process.env.VUE_APP_API}/institucional/list-unidades-pai`)
          .then(response => this.itemsInstitucionalPai = response.data)
    },

    close () {
      this.$router.push('/institucional');
    },

    save () {
      this.carregando = true;

      let url = this.form.id ? 'update' : 'store';


      api.post(`${process.env.VUE_APP_API}/institucional/${url}`, this.serialize(), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then((response) => {
            this.message = response.data.message;
            this.dialogConfirm = true

          })
          .catch((erro) => {
            this.showErro = true;
            this.respErro = [];

            if(erro.response.data.message && !erro.response.data.errors){
              this.respErro.push(erro.response.data.message);
            }
            else if(erro.response.data.errors) {
              let erros = erro.response.data.errors;

              Object.keys(erros).forEach((key) => {
                this.respErro.push(erros[key][0]);
              })

            }
          })
          .finally(() => this.carregando = false)
    },

    closeConfirm () {
      this.dialogConfirm = false
      if(!this.form.id){
        this.$router.push('/institucional');
      }else {
        this.$router.push('/institucional');
        //this.$router.push(`/institucional/edit/${this.form.id}`);
      }
    },

    removeAcento (text){
      text = text.toLowerCase();
      text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
      text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
      text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
      text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
      text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
      text = text.replace(new RegExp('[Ç]','gi'), 'c');
      return text;
    },
  }
}
</script>

<style>
#imagemM{
  /* float: right; */
  width: 230px;
  height: 250px;
  border-radius: 5px;
}

ul{
  list-style: none;
}

#descricao {
  height: 200px;
}
</style>