<template>
  <v-card>
    <v-progress-linear
      :active="editando"
      indeterminate
      rounded
      height="6"
    ></v-progress-linear>

    <h1 class="ml-4">{{ formTitle }}</h1>

    <v-card-text>
      <form id="form" enctype="application/x-www-form-urlencoded">
        <input type="hidden" v-model="form.id" />

        <v-row>
          <v-col class="col-12 col-md-8">
            <v-text-field
              v-model="form.titulo"
              :rules="obrigatorio"
              label="Título *"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-8">
            <label for="descricao">Descrição *</label>
            <tiptap v-model="form.descricao" />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-12 col-md-4" style="font-size: 12px">
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="Upload"
              v-model="form.foto"
              id="foto"
            ></v-file-input>

            <span>
              O arquivos deve ter até <b>2 MB</b>.<br />
              Tipos de arquivos permitidos: <b>png, gif, jpg e jpeg</b>.
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-12 col-md-4">
            <!-- <p>Tamanho máximo: 10M</p> -->
            <div v-show="imagem">
              <img :src="imagem" id="imagemM" />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-4 col-md-2" style="font-size: 12px">
            <div class="mb-3" style="color: #1e88e5">Publicação</div>

            <v-checkbox
              v-model="form.publicado"
              :label="textoPublicacao"
              color="success"
              :value="form.publicado ? form.publicado : ''"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col class="col-8 col-md-3" style="font-size: 12px">
            <div v-if="usr_cadastro" class="mb-3">
              <div class="mb-3" style="color: #1e88e5">Autoria</div>

              Escrito por:
              <b>{{ usr_cadastro }}</b> <br />
              Em:
              <b>{{ dt_cadastro }}</b>
            </div>

            <div v-if="usr_alteracao" class="mb-3">
              Alterado por:
              <b>{{ usr_alteracao }}</b> <br />
              Em:
              <b>{{ dt_alteracao }}</b>
            </div>

            <div v-if="dt_publicacao && form.publicado">
              Publicado por:
              <b>{{ usr_publicacao }}</b> <br />
              Em:
              <b>{{ dt_publicacao }}</b>
            </div>
          </v-col>
        </v-row>
      </form>
    </v-card-text>

    <v-row>
      <template v-if="showErro">
        <v-col cols="10">
          <v-alert type="error" elevation="2" prominent v-show="true">
            <ul>
              <li v-for="(i, indice) in respErro" :key="indice">{{ i }}</li>
            </ul>
          </v-alert>
        </v-col>
      </template>
    </v-row>

    <br />
    <v-card-actions class="pa-6">
      <v-row>
        <v-btn
          class="mr-1"
          color="blue darken-1"
          dark
          @click="save"
          :loading="this.carregando"
        >
          Salvar
        </v-btn>

        <v-btn color="red darken-1" dark @click="close"> Pesquisar </v-btn>
      </v-row>
    </v-card-actions>
    <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">
          {{ message }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeConfirm"> Ok </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import api from "@/api";
import Tiptap from "../../components/Tiptap.vue"

export default {
  data() {
    return {
      dialogConfirm: false,
      message: "",
      imagem: "",
      form: {
        id: this.$route.params.id,
        titulo: "",
        descricao: "",
        url: "",
        foto: null,
        publicado: "",
      },
      editando: true,
      carregando: false,
      showErro: false,
      respErro: "",
      usr_cadastro: "",
      usr_alteracao: "",
      usr_publicacao: "",
      dt_cadastro: "",
      dt_alteracao: "",
      dt_publicacao: "",
      valid: false,
      obrigatorio: [(v) => !!v || "Este campo é obrigatório"],
      regras: [
        (v) => !v || v.size < 10000000 || "O arquivo deve ter até 2 MB!",
      ],
    };
  },

  components: {
    Tiptap
  },

  computed: {
    formTitle() {
      return "Edição de Notícia";
    },
    textoPublicacao() {
      if (this.form.id) {
        return this.form.publicado ? "Publicado" : "Não publicado";
      } else {
        return this.form.publicado ? "Publicar" : "Não publicar";
      }
    },
  },

  created() {
    api
      .get(`${process.env.VUE_APP_API}/noticia/edit/${this.form.id}`) //erro
      .then((r) => {
        console.log(r)
        // this.form.id = r.data.id;
        this.form.titulo = r.data.titulo;
        this.form.descricao = r.data.descricao;
        this.form.url = r.data.url;
        this.form.publicado = r.data.publicado;
        this.form.foto = null;

        if (r.data.foto != null) {
          this.imagem = `data:${r.data.mime};base64, ${r.data.foto}`;
        }

        this.usr_cadastro = r.data.usuario_cad;
        this.usr_alteracao = r.data.usuario_alt;
        this.usr_publicacao = r.data.usuario_publicacao;
        this.dt_cadastro = r.data.created_at;
        this.dt_alteracao = r.data.updated_at;
        this.dt_publicacao = r.data.dt_publicacao;
      })
      .finally(() => (this.editando = false));
  },

  methods: {
    serialize() {
      let form = document.getElementById("form");
      let formData = new FormData(form);

      for (let campo in this.form) {
        formData.append(campo, this.form[campo] ?? "");
      }

      let fotoInput = document.querySelector("#foto");
      let arquivo = fotoInput.files[0];
      if (typeof arquivo !== "undefined") {
        formData.append("foto", arquivo);
      }
      return formData;
    },

    setURL() {
      let texto = this.removeAcento(this.form.titulo);
      texto = texto.replace(/( )+/g, "-");
      this.form.url = `noticia/${texto}`;
    },

    close() {
      this.$router.push("/noticia");
    },

    save() {
      this.carregando = true;

      let url = this.form.id ? "update" : "store";

      api
        .post(`${process.env.VUE_APP_API}/noticia/${url}`, this.serialize(), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.message = response.data.message;
          this.dialogConfirm = true;
        })
        .catch((erro) => {
          if (erro.response) {
            let erros = erro.response.data.errors;
            this.showErro = true;
            this.respErro = [];

            if (typeof erros == "string") {
              this.respErro.push(erros);
            } else {
              Object.keys(erros).forEach((key) => {
                this.respErro.push(erros[key][0]);
              });
            }
          }
        })
        .finally(() => (this.carregando = false));
    },

    closeConfirm() {
      this.dialogConfirm = false;
      if (!this.form.id) {
        this.$router.push("/noticia");
      } else {
        this.$router.push("/noticia");
      }
    },

    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");
      return text;
    },
  },
};
</script>

<style>
#imagemM {
  /* float: right; */
  width: 230px;
  height: 250px;
  border-radius: 5px;
}

ul {
  list-style: none;
}
</style>
