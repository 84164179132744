<template>
  <div>Seja bem vindo</div>
</template>

<script>


  export default {
    name: 'Home',


  }
</script>
