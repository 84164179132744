<template>
  <div></div>
</template>

<script>

  import api from '@/api';

  export default {
    name: 'Logout',

    created() {
      api.get('/user/logout')
          .finally(() => {
            this.$store.commit("LOGOUT");
            this.$router.push('/');
          })
    }
  }
</script>
