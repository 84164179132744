import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home'

import Login from '../views/Login'
import Logout from '../views/Logout'

import Banner from '../views/Banner/Index'
import BannerCreate from '../views/Banner/Create'
import BannerEdit from '../views/Banner/Edit'

import Popup from '../views/Popup/Index'
import PopupCreate from '../views/Popup/Create'
import PopupEdit from '../views/Popup/Edit'

import Noticia from '../views/Noticia/Index'
import NoticiaCreate from '../views/Noticia/Create'
import NoticiaEdit from '../views/Noticia/Edit'

import Categorias from '../views/Categorias/index.vue'
import CategoriaCreate from '../views/Categorias/Create.vue'
import CategoriaEdit from '../views/Categorias/Edit.vue'

import GaleriaFotos from '../views/GaleriaFotos/Index.vue'
import GaleriaFotosCreate from '../views/GaleriaFotos/Create.vue'

import Institucional from '../views/Institucional/Index'
import InstitucionalCreate from '../views/Institucional/Create'

import Servicos from '../views/Servicos/Index'
import ServicosCreate from '../views/Servicos/Create'

import Transparencia from '../views/Transparencia/Index'
import TransparenciaCreate from '../views/Transparencia/Create'

import Video from '../views/Video/Index'
import VideoCreate from '../views/Video/Create'

import Documento from '../views/Documentos/Index'
import DocumentoCreate from '../views/Documentos/Create'

import Pagina from '../views/Pagina/Index'
import PaginaCreate from '../views/Pagina/Create'

import AtosNormativos from '../views/AtosNormativos/Index'
import AtosNormativosCreate from '../views/AtosNormativos/Create'

import Desaparecido from '../views/Desaparecidos/Index'
import DesaparecidoCreate from '../views/Desaparecidos/Create'
import DesaparecidoEdit from '../views/Desaparecidos/Edit'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Login
  },
  {
    path: '/home',
    component: Home
  },


  {
    path: '/institucional',
    component: Institucional,
  },
  {
    path: '/institucional/create/',
    component: InstitucionalCreate,
  },
  {
    path: '/institucional/edit/:id',
    component: InstitucionalCreate,
  },


  {
    path: '/banner',
    component: Banner,
  },
  {
    path: '/banner/create',
    component: BannerCreate,
  },
  {
    path: '/banner/edit/:id',
    component: BannerEdit,
  },


  {
    path: '/popup',
    component: Popup,
  },
  {
    path: '/popup/create',
    component: PopupCreate,
  },
  {
    path: '/popup/edit/:id',
    component: PopupEdit,
  },
  {
    path: '/noticia',
    component: Noticia,
  },
  {
    path: '/noticia/create',
    component: NoticiaCreate,
  },
  {
    path: '/noticia/edit/:id',
    component: NoticiaEdit,
  },

  {
    path: '/categorias',
    component: Categorias
  },
  {
    path: '/categoria/create',
    component: CategoriaCreate
  },
  {
    path: '/categoria/edit/:id',
    component: CategoriaEdit
  },

  {
    path: '/servicos',
    component: Servicos,
  },
  {
    path: '/servicos/create/',
    component: ServicosCreate,
  },
  {
    path: '/servicos/edit/:id',
    component: ServicosCreate,
  },
  {
    path: '/transparencia',
    component: Transparencia,
  },
  {
    path: '/transparencia/create/',
    component: TransparenciaCreate,
  },
  {
    path: '/transparencia/edit/:id',
    component: TransparenciaCreate,
  },

  {
    path: '/galeria-de-fotos',
    component: GaleriaFotos,
  },
  {
    path: '/galeria-de-fotos/create',
    component: GaleriaFotosCreate,
  },
  {
    path: '/galeria-de-fotos/edit/:id',
    component: GaleriaFotosCreate,
  },

  {
    path: '/video',
    component: Video,
  },
  {
    path: '/video/create',
    component: VideoCreate,
  },

  {
    path: '/video/edit/:id',
    component: VideoCreate,
  },
  {
    path: '/documentos',
    component: Documento,
  },
  {
    path: '/documento/create/',
    component: DocumentoCreate,
  },
  {
    path: '/documento/edit/:id',
    component: DocumentoCreate,
  },

  {
    path: '/pagina',
    component: Pagina,
  },
  {
    path: '/pagina/create/',
    component: PaginaCreate,
  },
  {
    path: '/pagina/edit/:id',
    component: PaginaCreate,
  },

  {
    path: '/atos-normativos',
    component: AtosNormativos,
  },
  {
    path: '/atos-normativos/create/',
    component: AtosNormativosCreate,
  },
  {
    path: '/atos-normativos/edit/:id',
    component: AtosNormativosCreate,
  },

  {
    path: '/desaparecido',
    component: Desaparecido,
  },
  {
    path: '/desaparecido/create/',
    component: DesaparecidoCreate,
  },
  {
    path: '/desaparecido/edit/:id',
    component: DesaparecidoEdit,
  },


  {
    path: '/logout',
    component: Logout
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
