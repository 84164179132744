<template>
  <v-card>
    <h1 class="ml-4">Cadastro de Categoria</h1>

    <v-card-text>
      <form id="form">
        <v-row>
          <v-col class="col-12 col-md-6">
            <v-text-field
              v-model="form.nome"
              label="Nome *"
            ></v-text-field>
          </v-col>

          <v-col class="col-12 col-md-4" style="font-size: 12px">
            <v-switch
              v-model="form.ativa"
              :label="`Ativa: ${form.ativa.toString()}`"
            ></v-switch>
          </v-col>
        </v-row>
      </form>
    </v-card-text>

    <v-card-actions class="pa-6">
      <v-row>
        <v-btn
          class="mr-1"
          color="blue darken-1"
          dark
          @click="save"
          :loading="this.carregando"
        >
          Salvar
        </v-btn>

        <v-btn color="red darken-1" dark @click="closeConfirm"> Pesquisar </v-btn>
      </v-row>
    </v-card-actions>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">
          {{ message }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeConfirm"> Ok </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import api from "@/api";

export default {
  data() {
    return {
      dialog: false,
      message: '',
      carregando: false,
      form: {
        nome: "",
        ativa: true
      }
    }
  },

  methods: {

    save() {
      this.carregando = true
      api
      .post(`${process.env.VUE_APP_API}/categoria/store`, this.form)
      .then((response) => {
        this.message = 'Categoria Cadastrada com Sucesso.'
        this.dialog = true
        console.log(response)
      })
      .catch((erro) => {
        this.message = 'Falha ao Cadastrar Categoria.'
        this.dialog = true
        console.log(erro)
      })
      .finally(() => this.carregando = false)
    },

    closeConfirm() {
      this.dialog = false;
      this.$router.push("/categorias");
    }
  },
};
</script>

<style>
#imagemM {
  width: 230px;
  height: 250px;
  border-radius: 5px;
}

ul {
  list-style: none;
}
</style>
