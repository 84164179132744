import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//as constantes abaixo são truques para tornar mais simples recriar o estado inicial da sessão (quando usuário faz logout)
const getDefaultStateInstance = () => {//este método cria o modelo de sessão vazia
    return {
        usuario: {
            nome: '',
        },
        exibirMenu: false
    }
}

const state = getDefaultStateInstance();

export default new Vuex.Store({
    state,
    mutations: {
        /*
         * São convenções mutations:
         * - Estar em caixa alta
         * - Entre as palavras colocar _
         * - Apenas elas devem modificar um state. Actions não devem fazer isto.
         */
        EXIBIR_MENU(state, payload) {
            state.exibirMenu = payload;
        },
    
        SET_USUARIO(state, payload) {
          state.usuario.nome = payload;
        },

        SET_USUARIO_TOKEN(state, payload) {
            state.usuario.token = payload;
        },

        LIMPAR_TUDO() {
            //chama o método que gera um modelo de sessão vazia (recriando o estado inicial)
            Object.assign(state, getDefaultStateInstance())
            localStorage.removeItem('hash');
        },
    
        LOGOUT(state) {
          //chama o método que gera um modelo de sessão vazia (recriando o estado inicial)
          Object.assign(state, getDefaultStateInstance())
        }
    },
    getters: {
      IS_LOGADO(state) {
        return state.usuario.nome ? true : false;
      },
      GET_USUARIO(state) {
        return state.usuario.nome;
      }
    },
    actions: {
        registrarUsuario(context, payload) {

            context.commit("SET_USUARIO", payload.nome);
            // context.commit("ATUALIZAR_USUARIO_TOKEN", payload.token);

            window.localStorage.setItem('usuario', payload.token);
        },
        // atualizarToken(context, payload) {
        //     context.commit("ATUALIZAR_USUARIO_TOKEN", payload);
        // },
    },
    modules: {
    }
})
