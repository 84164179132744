<template>
  <v-app id="inspire">
    <v-app-bar app dark v-if="$store.state.exibirMenu">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ sistema }}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" v-if="$store.state.exibirMenu" dark app width="256">
      <v-list>
        <v-list-item>
          <v-list-item-avatar style="margin-left: 5em;">
            <v-img src="@/assets/icon-policia.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item v-if="$store.state.usuario.nome">
          {{ $store.state.usuario.nome }}
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item :to="item.link" v-for="(item, i) in items" :key="i">
            <v-list-item-icon>
              <v-icon v-text="item.icon" :color="item.color"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>


    <v-main style="margin: 5px;">
      <router-view />
    </v-main>

    <v-footer app width="100%" dark class="text-center">
      <v-card-text class="white--text">
        {{ sistema }} © {{ new Date().getFullYear() }} —
        <strong>DIME/PCPA</strong>
      </v-card-text>
    </v-footer>
  </v-app>

</template>


<script>

import api from '@/api';

export default {
  name: 'App',
  data: () => ({
    sistema: process.env.VUE_APP_SISTEMA,
    selectedItem: 0,
    drawer: null,
    items: [
      { text: 'Home', icon: 'mdi-home', color: 'grey', link: '/home' },
      { text: 'Atos Normativos', icon: 'mdi-book-open-variant-outline', color: 'grey', link: '/atos-normativos/create' },
      { text: 'Banner', icon: 'mdi-aspect-ratio', color: 'grey', link: '/banner/create' },
      { text: 'Categorias', icon: 'mdi-tag-multiple', color: 'grey', link: '/categoria/create' },
      { text: 'Desaparecidos', icon: 'mdi-account-search', color: 'grey', link: '/desaparecido/create' },
      { text: 'Documentos Anexo', icon: 'mdi-attachment', color: 'grey', link: '/documento/create' },
      { text: 'Fotos', icon: 'mdi-image', color: 'grey', link: '/galeria-de-fotos/create' },
      { text: 'Institucional', icon: 'mdi-home-city', color: 'grey', link: '/institucional/create' },
      { text: 'Notícia', icon: 'mdi-newspaper-variant-outline', color: 'grey', link: '/noticia/create' },
      { text: 'Página', icon: 'mdi-file-document', color: 'grey', link: '/pagina/create' },
      { text: 'Popup', icon: 'mdi-picture-in-picture-top-right', color: 'grey', link: '/popup/create' },
      { text: 'Serviços', icon: 'mdi-cog', color: 'grey', link: '/servicos/Create' },
      { text: 'Transparência', icon: 'mdi-eye', color: 'grey', link: '/transparencia/create' },
      { text: 'Vídeos', icon: 'mdi-youtube', color: 'grey', link: '/video/create' },
      { text: 'Sair', icon: 'mdi-logout', color: 'grey', link: '/logout' },

    ],
    icones: [
      'mdi-home',
      'mdi-email',
      'mdi-calendar',
      'mdi-delete'
    ]
  }),
  created() {

    if (this.$router.currentRoute.path !== '/') {

      this.$store.commit('EXIBIR_MENU', true);

      //verifica se o usuário perdeu a sessão
      if (!this.$store.getters.IS_LOGADO) {
        this.$store.commit("LOGOUT");
        this.$router.push('/');
      } else {
        this.$store.commit('EXIBIR_MENU', true);
      }

    }
    else {
      this.$store.commit('EXIBIR_MENU', false);
    }

  },
  methods: {
    logout() {
      api.post('/logout')
        .finally(() => {
          this.$store.commit("LOGOUT");
          this.$router.push('/');
        })
    }
  }
};
</script>
