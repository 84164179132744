<template>
  <v-container>
    <v-card
      elevation="16"
      width="100%"
      max-width="400px"
      class="mt-16 mx-auto"
      :loading="this.carregando">

      <v-toolbar dark color="#333 darken-3" height="80" elevation="0">
        <v-img src="@/assets/icon-policia.png" max-width="100" class="ml-n5"></v-img>

        <v-toolbar-title style="font-size: 16px; font-weight: bold;">
          POLÍCIA CIVIL DO ESTADO DO PARÁ
          <br>
          Acessar CMS
        </v-toolbar-title>

      </v-toolbar>

      <v-card-text>
        <v-form id="form" 
          lazy-validation
          ref="form" 
          class="mt-4 pl-3 pr-3" 
          @submit.prevent="validate()">

          <v-text-field type="mail" 
            label="E-mail"                        
            v-model="form.email" 
            :rules="[rules.required, rules.emailRules]" 
            append-icon="mdi-email"
          ></v-text-field>
          
          <v-text-field 
            :append-icon="!verSenha ? 'mdi-eye' : 'mdi-eye-off'"
            :type="!verSenha ? 'password' : 'text'"
            label="Senha"
            v-model="form.senha" 
            :rules="[rules.required, rules.min]"
            @click:append="verOuEsconderSenha"
          ></v-text-field>

          <v-btn 
            type="submit"
            block 
            color="primary" 
            elevation="7"
            :loading="this.carregando"  
            class="mt-5">Entrar</v-btn>

          <v-alert
            v-show="this.showError"
            class="mt-5"
            border="left"
            dense
            elevation="13"
            prominent
            type="error"
          >{{this.message}}</v-alert>

        </v-form>
        <div class="mt-9 text-center">
          <span style="font-size: 13px">{{sistema}} © 2022 - DIME - Diretoria de Informática,<br>Manutenção e Estatística.</span>
        </div>
      </v-card-text>
    </v-card>

    
  </v-container>
</template>

<script>

  import api from '@/api';

  export default {
    name: 'Login',
    data() {
      return {
        form: {
          email: '',
          senha: ''
        },
        verSenha: false,
        message: '',
        showError: false,
        carregando: false,
        sistema: process.env.VUE_APP_SISTEMA,
        rules: {
          required: value => !!value || 'Campo obrigatório.',
          number: value => {
            const pattern = /\d/
            return pattern.test(value) || 'Somente números'
          },
          min: v => v.length >= 6 || 'Mínimo 6 caracteres',
          emailRules: v => {
            return /.+@.+/.test(v) || 'E-mail deve ser válido'
          }
        }
      }
    },

    methods: {
      
      verOuEsconderSenha() {
        this.verSenha = !this.verSenha;
      },

      validate() {
        if(this.$refs.form.validate())          
          this.autenticar();
      },

      autenticar() {
        this.carregando = true;

        api.get(`${process.env.VUE_APP_BASE_URL_API}/sanctum/csrf-cookie`)
          .then(() => {
              api.post('/user/auth', this.form)
                .then((json) => {
                  this.$store.commit('EXIBIR_MENU', true);
                  this.$store.commit('SET_USUARIO', json.data.nome);
                  this.$router.push('/home');
                })
                .catch((error) => {
                  this.showError = true;
                  this.message = error.response.data.message;
                })
                .finally(() => this.carregando = false)
          });
      }
    }      
  }
</script>
