<template>
  <v-card>
    <v-progress-linear
        :active="carregando"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>

    <h1 class="ml-4">
      Categorias
      <v-btn style="width: 110px; float: right" color="blue darken-1" dark class="d-none d-sm-flex mr-4 mt-3" to="/categoria/create">
        Cadastrar
      </v-btn>
    </h1>
    <br />
    <v-form
        @submit.prevent="pesquisar"
    >
      <v-row>
        <v-col class="ml-4 col-md-6">
          <v-text-field
            label="Pesquise uma categoria e tecle enter..."
            v-model="titulo"
            clearable
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col class="col-md-2">
          <v-btn style="display: none" type="submit">Pesquisar</v-btn>          
        </v-col>
      </v-row>
    </v-form>

    <v-card-text>
      <v-data-table
        :headers="colunas"
        :items="listarCategorias"
        light
      >      
        <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6">
                  Você gostaria de deletar essa Categoria?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1" 
                    text 
                    @click="closeDelete"
                  >
                    Cancelar
                  </v-btn>
                  
                  <v-btn 
                    color="blue darken-1" 
                    text 
                    @click="deleteItemConfirm"
                  >
                    OK
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          
        </template>
        <template v-slot:item.actions="{ item }" >
          <v-btn icon :to="'/categoria/edit/'+item.id" id="icone">
            <v-icon
              small
              class="mr-2"
              color="rgb(77 85 170)"
            >
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-icon
            small
            @click="deleteItem(item)"
            color="rgb(175 43 10)"
          >
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:item.ativa="{ item }" >
          <v-chip
            :color="item.ativa ? 'green' : 'red'"
            style="font-size: 12px; color: white;"
          >
          {{item.ativa ? 'Ativa': 'Inativa'}}
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">
          {{ message }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"> Ok </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import api from '@/api'

  export default {
    data() {
      return {
        dialog: false,
        message: '',
        linhaSelecionada: '',
        dialogDelete: false,
        carregando: false,
        colunas: [
          {
            text: 'Nome',
            value: 'nome'
          },
          {
            text: 'Status',
            value: 'ativa'
          },
          { 
            text: 'Actions', 
            value: 'actions', 
            sortable: false 
          },
        ],
        listarCategorias: [],
        titulo: '',
      }   
    },
    watch: {
      titulo(novo) {
        if(novo === null) {
          this.titulo = '';
        }
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created() {
       this.initialize()  
    },

    methods: {
      initialize(){
        this.carregando = true
        api.get(`${process.env.VUE_APP_API}/categorias`)
          .then(r => {
              this.listarCategorias = r.data;
          })
          .finally(() => this.carregando = false)
      },

      pesquisar(){
        this.carregando = true
        api.get(`${process.env.VUE_APP_API}/categoria/pesquisa/${this.titulo}`)
          .then(r => {
              this.listarCategorias = r.data;
              let array = r.data
              if (array.length === 0) {
                this.message = `Não encontramos categorias com ${this.titulo}`
                this.dialog = true
              }
          })
          .catch((erro) => {
            this.message = 'Erro de pesquisa'
            this.dialog = true
            console.log(erro)
          })
          .finally(() => this.carregando = false)
      },

      editItem(i) {
        return `/noticia/edit/${i.id}`;     
      },
 
      deleteItem (item) {
        this.linhaSelecionada = item;
        this.dialogDelete = true
      },
      
      deleteItemConfirm () {
        api.get(`${process.env.VUE_APP_API}/categoria/delete/${this.linhaSelecionada.id}`)
        .then(() => {
          this.closeDelete()
          this.initialize()
        })  
      },

      closeDelete () {
        this.dialogDelete = false;
      }
    },
  }
</script>

<style scoped>
  #icone{
    width: 25px;
  }
</style>